$assetsPath: "../image";
$appBackground: "fretador-fundo.png";

$headerHeight: 80px;
$filterHeight: 100px;
$filterHeightFreight: 130px;

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  background: url(#{$assetsPath}/#{$appBackground}) no-repeat center center
    fixed;
  background-size: 100% 100%;
}

html,
body,
#root {
  height: 100%;
  margin: auto;
  overflow: hidden;
  min-width: 360px !important;
}

#root {
  overflow: auto !important;
}

.main-header {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: $headerHeight;
  width: 100%;
  background-color: #fff;
  box-shadow: 0px -2px 11px 0px rgba(0, 0, 0, 0.3);
  z-index: 3;
}

.main-content {
  display: flex;
  justify-content: center;
  padding-top: $headerHeight;
}

.main-filter + .main-content {
  padding-top: $headerHeight + $filterHeight;
}

.main-filter,
.main-filter-freight {
  position: fixed;
  margin-top: $headerHeight;
  height: $filterHeight;
  width: 100%;
  background-color: #1a5b76;
  z-index: 3;
}

.main-filter-freight + .main-content {
  padding-top: $headerHeight + $filterHeightFreight;
}

.main-filter-freight {
  height: $filterHeightFreight;
}

@media (max-width: "1024px") {
  $filterHeight: 150px;
  $filterHeightFreight: 260px;

  .main-filter {
    height: $filterHeight;
  }
  .main-filter-freight {
    height: $filterHeightFreight;
  }

  .main-filter + .main-content {
    padding-top: calc(#{$headerHeight} + #{$filterHeight});
  }
  .main-filter-freight + .main-content {
    padding-top: calc(#{$headerHeight} + #{$filterHeightFreight});
  }

}

@media (max-width: "924px") {
  $filterHeight: 180px;
  $filterHeightFreight: 260px;

  .main-filter {
    height: $filterHeight;
  }
  .main-filter-freight {
    height: $filterHeightFreight;
  }

  .main-filter + .main-content {
    padding-top: calc(#{$headerHeight} + #{$filterHeight});
  }
  .main-filter-freight + .main-content {
    padding-top: calc(#{$headerHeight} + #{$filterHeightFreight});
  }
}

@media (max-width: "724px") {
  $filterHeight: 220px;
  $filterHeightFreight: 300px;

  .main-filter {
    height: $filterHeight;
  }
  .main-filter-freight {
    height: $filterHeightFreight;
  }

  .main-filter + .main-content {
    padding-top: calc(#{$headerHeight} + #{$filterHeight});
  }
  .main-filter-freight + .main-content {
    padding-top: calc(#{$headerHeight} + #{$filterHeightFreight});
  }
}

@media (max-width: "499px") {
  $filterHeight: 220px;
  $filterHeightFreight: 300px;

  .main-filter {
    height: $filterHeight;
  }
  .main-filter-freight {
    height: $filterHeightFreight;
  }
  
  .main-filter + .main-content {
    padding-top: calc(#{$headerHeight} + #{$filterHeight});
  }
  .main-filter-freight + .main-content {
    padding-top: calc(#{$headerHeight} + #{$filterHeightFreight});
  }
}

@media (max-width: "470px") {
  $filterHeight: 250px;
  $filterHeightFreight: 330px;

  .main-filter {
    height: $filterHeight;
  }
  .main-filter-freight {
    height: $filterHeightFreight;
  }

  .main-filter + .main-content {
    padding-top: calc(#{$headerHeight} + #{$filterHeight});
  }
  .main-filter-freight + .main-content {
    padding-top: calc(#{$headerHeight} + #{$filterHeightFreight});
  }
}

@media (max-width: "368px") {
  $filterHeight: 300px;
  $filterHeightFreight: 330px;

  .main-filter {
    height: $filterHeight;
  }
  .main-filter-freight {
    height: $filterHeightFreight;
  }

  .main-filter + .main-content {
    padding-top: calc(#{$headerHeight} + #{$filterHeight});
  }
  .main-filter-freight + .main-content {
    padding-top: calc(#{$headerHeight} + #{$filterHeightFreight});
  }
}
